import RouterLink from 'next/link'
import { is, values, pick } from 'ramda'

import { isURL } from '@utils/url'
import { UIStyles } from '@lib/UIComponents/types'
import { Link as UILink } from '@lib/UIComponents'
import { forwardRef } from 'react'

export type PathObject = {
  pathname?: string
  hash?: string
  search?: string
}

export type Path = string | PathObject

export type LinkProps = {
  sx?: UIStyles
  to?: Path | string
  title?: string
  external?: boolean
  prefetch?: boolean
  shallow?: boolean
  target?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  children?: React.ReactNode
}

const mountURL = (to: Path): string =>
  is(String, to)
    ? (to as string)
    : values(pick(['pathname', 'hash', 'search'], to)).join('')

const Link = forwardRef(({ children, to = '', ...props }: LinkProps, ref) => {
  const { external = true, prefetch, target, shallow, ...UIProps } = props
  const isExternal = external || isURL(to.toString())

  if (isExternal) {
    const rel = target === '_blank' ? 'noreferrer noopener' : undefined

    return (
      <UILink
        href={mountURL(to)}
        target={target}
        rel={rel}
        ref={ref}
        {...UIProps}
      >
        {children}
      </UILink>
    )
  }

  return (
    <RouterLink
      href={to}
      prefetch={prefetch}
      shallow={Boolean(shallow)}
      passHref
    >
      <UILink href={mountURL(to)} target={target} {...UIProps}>
        {children}
      </UILink>
    </RouterLink>
  )
})

export default Link
