// istanbul ignore file
export type Breakpoints = Array<string>

export const defaultMediaSizes = {
  ScreenXS: 512,
  ScreenS: 768,
  ScreenM: 1024,
  ScreenXL: 1280
}

export const mediaSizesValues = Object.values(defaultMediaSizes)

const breakpoints: Breakpoints = mediaSizesValues.map(
  (v: number) => `${v / 16}em`
)

export default breakpoints
